<template>
  <div class="vab-avatar">
    <vab-button
      v-if="!isLogin"
      class="login"
      size="small"
      type="primary"
      @click="toLogin"
    >
      立即登录
    </vab-button>
    <el-dropdown
      v-else
      :teleported="false"
      @command="handleCommand"
      @visible-change="handleVisibleChange"
    >
      <div class="username">
        <img alt="" class="avatar" src="/@/assets/flower_pig/头像.png" />
        {{ username }}
        <img
          alt=""
          class="down_arrow"
          src="/@/assets/flower_pig/箭头 向下.png"
        />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="logout">
            <img
              alt=""
              class="logout"
              src="/@/assets/flower_pig/退出登录.png"
            />
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts">
  interface propType {
    isLogin: boolean
    username: string
  }
  defineProps<propType>()
  const router = useRouter()
  import { passport_logout_url, sso_logout_url } from '~/src/config'
  import { useUserStore } from '/@/store/modules/user'
  import Cookies from 'js-cookie'

  defineOptions({
    name: 'VabAvatar',
  })
  const userStore = useUserStore()
  const { logout } = userStore
  const active = ref<boolean>(false)

  const handleVisibleChange = (value: boolean) => {
    active.value = value
  }
  const handleCommand = async (command: any) => {
    switch (command) {
      case 'logout':
        await logout()
        if (Cookies.get('account_type') === '0')
          window.location.href = sso_logout_url
        else window.location.href = passport_logout_url
        router.push('/')
    }
  }
  const toLogin = () => {
    router.push('/login')
  }
</script>

<style lang="scss" scoped>
  .vab-avatar {
    margin-left: 20px;
    .el-dropdown {
      --el-dropdown-menuItem-hover-fill: #fff;
      --el-dropdown-menuItem-hover-color: var(--el-color-primary);
      :deep(.el-popper__arrow) {
        display: none;
      }
      .username {
        .avatar {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        display: flex;
        align-items: center;
        outline: none;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #101626;
        text-align: right;
        font-weight: 500;
        .down_arrow {
          width: 12px;
          height: 12px;
          margin-left: 8px;
        }
      }
    }
  }
  .el-dropdown-menu {
    width: 132px;
    background-color: #fff;

    :deep(.el-dropdown-menu__item) {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #101626;
      font-weight: 400;
      padding-left: 24px;
    }
    :deep(.el-dropdown-menu__item:not(.is-disabled):focus) {
      background: rgba(127, 0, 255, 0.06);
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #7f00ff;
      line-height: 22px;
      font-weight: 400;
    }
    .logout {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
</style>
