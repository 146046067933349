<template>
  <el-menu-item :index="itemOrMenu.path" @click="handleLink">
    <span class="item-title" :style="{ color: is_Show ? 'black' : '' }">
      {{ itemOrMenu.title }}
    </span>
    <img
      v-if="is_Show"
      alt=""
      class="selected"
      src="/@/assets/flower_pig/选中条.png"
    />
  </el-menu-item>
</template>

<script setup lang="ts">
  import { MenuType } from '/@/types/menu'
  import { isExternal } from '/@/utils/validate'
  interface propType {
    itemOrMenu: MenuType
  }
  const props = defineProps<propType>()

  const route = useRoute()
  const router = useRouter()

  const handleLink = () => {
    const routePath = props.itemOrMenu.path as string
    if (isExternal(routePath)) window.location.href = routePath
    else if (route.path !== routePath) {
      router.push(routePath)
    }
  }
  const is_Show = computed(() => {
    const path = route.matched[0].path
    return path !== '/manage'
  })
</script>

<style lang="scss" scoped>
  .el-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-bottom: none !important;
    .item-title {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold;
      color: black;
      font-weight: 500;
    }

    .selected {
      position: absolute;
      bottom: 10px; /* 调整图片位置，使其在标题下方 */
      left: 50%;
      transform: translateX(-50%);
      width: 16px;
      height: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
    }
    &.is-active {
      .selected {
        opacity: 1;
        visibility: visible;
      }
      .item-title {
        font-family: PingFangSC-Medium;
        color: #7f00ff;

        border-radius: 8px;
      }
    }
  }
  .el-menu-item .is-active {
    border-bottom: none !important;
  }
</style>
