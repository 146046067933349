<template>
  <div v-if="tenants.length > 1" class="vab-tenant">
    <div class="switch-tenant">
      <img alt="" class="tenant-icon" src="/@/assets/flower_pig/平台切换.png" />
    </div>
    <el-select
      v-model="vabTenant"
      class="autowidth-select"
      filterable
      size="small"
      style=""
      @change="handleCommand"
    >
      <el-option
        v-for="item in tenants"
        :key="item.tenant_key"
        :label="item.tenant_key"
        :value="item.tenant_key"
      />
    </el-select>
  </div>
</template>

<script lang="ts" setup>
  import { useUserStore } from '/@/store/modules/user'
  import { gp } from '/@vab/plugins/vab'

  defineOptions({
    name: 'VabTenant',
  })

  interface propType {
    redirectPath: string
  }

  const prop = defineProps<propType>()
  const userStore = useUserStore()
  const router = useRouter()
  const { tenant, tenants } = storeToRefs(userStore)
  const $baseConfirm = inject<any>('$baseConfirm')

  const vabTenant = ref('')
  watch(
    tenant,
    () => {
      vabTenant.value = tenant.value.tenant_key
    },
    {
      immediate: true,
    }
  )

  const handleCommand = (value) => {
    vabTenant.value = value
    const tenant_key = JSON.parse(
      localStorage.getItem('selectedTenant')!
    ).tenant_key
    $baseConfirm(
      `当前租户为${tenant_key}:,将切换到:${value}`,
      null,
      async () => {
        const tenant = tenants.value.find((tenant) => {
          return tenant.tenant_key === value
        })
        localStorage.setItem('selectedTenant', JSON.stringify(tenant))
        localStorage.removeItem('selectedPartner')
        gp.$baseMessage('租户切换成功', 'success', 'vab-hey-message-success')
        await router.push(prop.redirectPath)
        location.reload()
      },
      () => {
        vabTenant.value = tenant_key
      }
    )
  }
</script>

<style lang="scss" scoped>
  .vab-tenant {
    left: 6px;
    ::v-deep(.el-select) {
      --el-select-input-focus-border-color: none;
    }
    ::v-deep(.el-input__wrapper) {
      background-color: transparent;
      border: none;
      box-shadow: none !important;
    }
    ::v-deep(.el-input__inner) {
      color: black;
      font-weight: 600;
      font-size: 14px;
    }
    .switch-tenant {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-right: 20px;
      outline: none;
      color: black;
      .tenant-icon {
        position: absolute;
        top: 17px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .autowidth-select {
    margin-left: 30px;
    width: 120px;

    ::v-deep(.el-input) {
      width: auto !important;
    }
  }
</style>
