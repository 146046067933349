<template>
  <vab-menu-item v-if="menuComponent === 'VabMenuItem'" :item-or-menu="item" />
  <vab-sub-menu v-else-if="menuComponent === 'VabSubMenu'" :item-or-menu="item">
    <template v-if="item.children && item.children.length">
      <vab-menu
        v-for="route in item.children"
        :key="route.path"
        :item="route"
      />
    </template>
  </vab-sub-menu>
</template>

<script setup lang="ts">
  import { MenuType } from '/@/types/menu'
  defineOptions({
    name: 'VabMenu',
  })
  interface propType {
    item: MenuType
  }
  const props = defineProps<propType>()
  const menuComponent = computed(() =>
    props.item.children ? 'VabSubMenu' : 'VabMenuItem'
  )
</script>

<style lang="scss" scoped></style>
