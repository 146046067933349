/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import type { VabRouteRecordRaw } from './types'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordName,
} from 'vue-router'
import Layout from '/@/layout/index.vue'
import { isHashRouterMode, base } from '/@/config'
import { setupPermissions } from '/@/router/permissons'
import { filterRoutes } from '/@/utils/routes'

export const Routes: VabRouteRecordRaw[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('/@/views/login/index.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('/@/views/error/403.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('/@/views/error/404.vue'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/errorPage',
    name: 'ErrorPage',
    component: Layout,
    meta: {
      hidden: true,
      title: '错误页',
      icon: '',
    },
    children: [
      {
        path: '403',
        name: 'Error403',
        component: () => import('/@/views/error/403.vue'),
        meta: {
          title: '403',
          icon: 'error-warning-line',
        },
      },
      {
        path: '404',
        name: 'Error404',
        component: () => import('/@/views/error/404.vue'),
        meta: {
          title: '404',
          icon: 'error-warning-line',
        },
      },
      {
        path: '500',
        name: 'Error500',
        component: () => import('/@/views/error/500.vue'),
        meta: {
          title: '500',
          icon: 'error-warning-line',
        },
      },
      {
        path: '503',
        name: 'Error503',
        component: () => import('/@/views/error/503.vue'),
        meta: {
          title: '503',
          icon: 'error-warning-line',
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      icon: '',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('/@/views/home/index.vue'),
        meta: {
          hidden: true,
          title: '首页',
          icon: '',
          noClosable: true,
        },
      },
    ],
  },
  {
    path: '/document',
    name: 'Document',
    component: Layout,
    meta: {
      title: '文档',
      icon: '',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'DocumentIndex',
        component: () => import('/@/views/document/index.vue'),
        meta: {
          hidden: true,
          title: '文档',
          icon: '',
          dynamicNewTab: true,
        },
      },
      {
        path: 'detail/:id',
        name: 'DocumentDetail',
        component: () =>
          import('/@/views/document/components/DidiDocument.vue'),
        meta: {
          hidden: true,
          title: '文档',
          icon: '',
          dynamicNewTab: true,
        },
      },
      {
        path: 'noFile',
        name: 'NoFile',
        component: () => import('/@/views/document/components/noFile.vue'),
        meta: {
          hidden: true,
          title: '无权限',
          icon: '',
          dynamicNewTab: true,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    name: 'NotFound',
    meta: {
      hidden: true,
    },
  },
  {
    path: '/manage',
    name: 'Manage',
    component: () => import('/@/views/manage/layouts/index.vue'),
    meta: {
      levelHidden: true,
    },
    // redirect: '/manage/debugTool/account',
    children: [
      {
        path: 'debugTool',
        meta: {
          title: '开发者工具',
        },
        name: 'DebugTool',
        children: [
          {
            path: 'freeScene',
            meta: {
              title: '自由场景',
            },
            name: 'FreeScene',
            component: () =>
              import('/@/views/manage/pages/DebugTool/FreeScene/index.vue'),
          },
          {
            path: 'pullOrder',
            meta: {
              title: '接单工具',
            },
            name: 'PullOrder',
            component: () =>
              import('/@/views/manage/pages/DebugTool/PullOrder/index.vue'),
          },
          {
            path: 'pushOrder',
            meta: {
              title: '发单工具',
            },
            name: 'PushOrder',
            component: () =>
              import('/@/views/manage/pages/DebugTool/PushOrder/index.vue'),
          },

          {
            path: 'account',
            name: 'Account',
            meta: {
              title: '账号管理',
              icon: '',
              dynamicNewTab: true,
            },
            component: () =>
              import('/@/views/manage/pages/DebugTool/Account/index.vue'),
          },
        ],
      },
      {
        path: 'apiAccess',
        name: 'ApiAccess',
        component: () => import('/@/views/manage/pages/ApiAccess/index.vue'),
        meta: {
          title: 'Api运力接入',
        },
      },
      {
        path: 'permission',
        name: 'Permission',
        meta: {
          title: '用户管理',
          icon: '',
        },
        children: [
          {
            path: 'users',
            name: 'UserList',
            component: () =>
              import('/@/views/manage/pages/permission/user/userList.vue'),
            meta: {
              title: '用户列表',
            },
          },
        ],
      },
      {
        path: 'notice',
        name: 'Notice',
        meta: {
          title: '公告中心',
          icon: '',
        },
        component: () => import('/@/views/manage/pages/notice/index.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: isHashRouterMode
    ? createWebHashHistory(base)
    : createWebHistory(base),
  routes: Routes as RouteRecordRaw[],
})

function fatteningRoutes(routes: VabRouteRecordRaw[]): VabRouteRecordRaw[] {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

function addRouter(routes: VabRouteRecordRaw[]) {
  routes.forEach((route: VabRouteRecordRaw) => {
    if (!router.hasRoute(route.name)) router.addRoute(route as RouteRecordRaw)
    if (route.children) addRouter(route.children)
  })
}

export function resetRouter(routes: VabRouteRecordRaw[] = Routes) {
  routes.map((route: VabRouteRecordRaw) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.getRoutes().forEach((route) => {
    if (route.name) {
      const routeName: RouteRecordName = route.name
      router.hasRoute(routeName) && router.removeRoute(routeName)
    }
  })
  addRouter(routes)
}

export function setupRouter(app: App<Element>) {
  // setRoutes()
  const routes = [...Routes]
  // 根据权限和rolesControl过滤路由
  const accessRoutes = filterRoutes([...routes], false)
  // 设置菜单所需路由

  // 根据可访问路由重置Vue Router
  resetRouter(accessRoutes)
  // if (authentication === 'intelligence') addRouter(asyncRoutes)
  setupPermissions(router)
  app.use(router)
  return router
}

export default router
