type Environment = 'development' | 'osim' | 'production'

const env = process.env.NODE_ENV as Environment

interface IUrlConfig {
  baseUrl: string
  index: string
}

const allUrlConfig: { [key in Environment]: IUrlConfig } = {
  development: {
    baseUrl: 'http://localhost:5173/api',
    index: '/',
  },
  osim: {
    baseUrl:
      'http://osim.intra.xiaojukeji.com/gs_osim106/kflower-biz-gs-pangu/pangu/open/v1',
    index: 'http://osim106.static.gs.osim.intra.xiaojukeji.com',
  },
  production: {
    baseUrl: 'https://open.xiaojukeji.com/pangu/open/v1',
    index: 'https://open.xiaojukeji.com',
  },
}

const urlConfig = allUrlConfig[env] || allUrlConfig.production

export default urlConfig
