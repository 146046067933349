/**
 * @description 4个子配置，cli配置|通用配置|主题配置|网络配置导出
 */
import { cliConfig } from '/@/config/cli.config'
import { settingConfig } from '/@/config/setting.config'
import { themeConfig } from '/@/config/theme.config'
import { netConfig } from '/@/config/net.config'
import { redirectConfig } from '/@/config/redirect.config.ts'
import { MenuType } from '/@/types/menu'

const config: { [key: string]: any } = {
  ...cliConfig,
  ...settingConfig,
  ...themeConfig,
  ...netConfig,
  ...redirectConfig,
}

export default config

export const {
  base,
  outDir,
  assetsDir,
  port,
  chunkSizeWarningLimit,
  open,
  cssCodeSplit,
  title,
  titleSeparator,
  titleReverse,
  abbreviation,
  copyright,
  keepAliveMaxNum,
  isHashRouterMode,
  routesWhiteList,
  loadingText,
  tokenName,
  tokenValue,
  tokenTableName,
  storage,
  recordRoute,
  logo,
  i18n,
  messageDuration,
  errorLog,
  loginInterception,
  authentication,
  supportVisit,
  rolesControl,
  uniqueOpened,
  defaultOpeneds,
  debounce,
  openFirstMenu,
  layout,
  themeName,
  menuWidth,
  columnStyle,
  color,
  fixedHeader,
  showProgressBar,
  showTabs,
  tabsBarStyle,
  showTabsIcon,
  showLanguage,
  showRefresh,
  showSearch,
  showTheme,
  showNotice,
  showFullScreen,
  showThemeSetting,
  showDark,
  foldSidebar,
  showPageTransition,
  contentType,
  timeout,
  successCode,
  statusName,
  messageName,
  sso_login_url,
  sso_logout_url,
  passport_login_url,
  passport_logout_url,
  interceptionRoute,
} = config

export const menu: MenuType[] = [
  {
    title: '首页',
    path: '/',
  },
  {
    title: '文档',
    path: '/document',
  },
]

import { Tools } from '@element-plus/icons-vue'
interface IconType {
  [key: string]: typeof Tools
}
export const icons: IconType = {
  tool: Tools,
}
