<template>
  <div v-if="judegLogin" class="fixed-box">
    <el-tooltip class="box-item" effect="light" placement="left-start">
      <template #content>
        <span style="height: 45px; display: flex; align-items: center">
          今日值班人：{{ personOnDuty }}
        </span>
      </template>
      <div class="on-duty">
        <vab-icon icon="customer-service-2-fill" />
        <div>值班人</div>
      </div>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
  import { judgeIsLogin } from '~/src/utils/cookies'
  import { useUserStore } from '/@/store/modules/user'
  import { loginInterception } from '~/src/config'
  const userStore = useUserStore()
  const { config } = storeToRefs(userStore)
  const personOnDuty = ref('')

  onMounted(() => {
    watch(config, () => {
      personOnDuty.value = config.value.duty.name
    })
  })
  const judegLogin = judgeIsLogin() || !loginInterception
</script>

<style lang="scss" scoped>
  .fixed-box {
    z-index: 10;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 300px;
    right: 10px;
    background: hsla(0, 0%, 100%, 0.9);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    .on-duty {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 6px 0;
      &:hover {
        color: #7b00f8;
      }
    }
  }
</style>
