<template>
  <el-config-provider
    :button="{
      autoInsertSpace: true,
    }"
  >
    <router-view />
  </el-config-provider>
</template>

<script lang="ts" setup></script>
