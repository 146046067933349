/**
 * 重定向url配置
 */
const passport_encode_url = encodeURIComponent(
  'https://open.xiaojukeji.com/pangu/open/v1/auth/ticket?account_type=1&redirectUrl=https://open.xiaojukeji.com'
)
const passport_logout_encode_url = encodeURIComponent(
  'https://open.xiaojukeji.com/pangu/open/v1/auth/logout?account_type=1&caller=1&redirectUrl=https://open.xiaojukeji.com'
)

export const redirectConfig: {
  [key: string]: string | number | Array<number | string>
} = {
  sso_login_url:
    'https://open.xiaojukeji.com/pangu/open/v1/auth/login?account_type=0&redirectUrl=https://open.xiaojukeji.com',
  sso_logout_url:
    'https://open.xiaojukeji.com/pangu/open/v1/auth/logout?caller=0&account_type=0&redirectUrl=https://open.xiaojukeji.com',
  passport_login_url: `https://open.xiaojukeji.com/pangu/open/v1/auth/login?account_type=1&redirectUrl=${passport_encode_url}`,
  passport_logout_url: `https://open.xiaojukeji.com/pangu/open/v1/auth/logout?&account_type=1&caller=0&redirectUrl=${passport_logout_encode_url}`,
}
