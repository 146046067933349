<template>
  <vab-app />
</template>

<script lang="ts" setup>
  import { useUserStore } from '/@/store/modules/user.ts'
  import { getLoginInfo } from './utils/cookies'
  const userStore = useUserStore()
  const { uid } = storeToRefs(userStore)
  onMounted(() => {
    const script = document.createElement('script')
    const random = Math.floor((1 + Math.random()) * 0x10000).toString(8)
    script.src = `//sec-aegisfe.didistatic.com/static/aegisfe/water-mark1.0.js?v=${random}`
    script.onload = initializeWaterMark
    document.body.appendChild(script)
    getLoginInfo()
  })

  const initializeWaterMark = () => {
    if (uid.value) {
      waterMark({
        systemId: '3000002',
        userId: uid.value,
        imgWidthDis: 100,
        imgHeightDis: 100,
        textStyle: 'rgba(0,0,0,0.03)',
        fontSize: '18px',
        containerEl: document.body,
      })
    }
  }
</script>
