import { Router } from 'vue-router'
import { loginInterception, interceptionRoute } from '/@/config'
import { judgeIsLogin } from '/@/utils/cookies'

export function setupPermissions(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const path = to.matched[0].path
    if (interceptionRoute.find((item: string) => item === path)) {
      if (judgeIsLogin() || !loginInterception) {
        next()
      } else next('/login')
    } else {
      next()
    }
  })
  router.afterEach((to) => {
    if (typeof to.meta.title === 'string') document.title = to.meta.title
  })
}
