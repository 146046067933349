import App from './App.vue'

import { setupVab } from '~/library'
import { setupStore } from '/@/store'
import { setupRouter } from '/@/router'
import ElementPlus from 'element-plus'
import omegaTracker from '@didi/omega-tracker-lite'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { getLoginInfo } from '/@/utils/cookies'

const app = createApp(App)
app.use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const OmegaDidifarm = omegaTracker.getTracker({
  appKey: 'omega174e066886',
  // 用户邮箱前缀
  userName: localStorage.getItem('token') as string,
  autoPerformance: true,
})

if (OmegaDidifarm != undefined) {
  OmegaDidifarm.trackEvent('wyc_qp_didifarm_visit_en', '开放门户页面访问', {})
}

setupVab(app)
setupStore(app)
setupRouter(app)
  .isReady()
  .then(() => app.mount('#app'))

getLoginInfo()
