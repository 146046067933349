/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl'
import { getUserInfo } from '/@/api/user/user'
import { getToken, removeToken, setToken } from '/@/utils/token'
import { isNum, isString } from '/@/utils/validate'
import cookie from 'js-cookie'
import { gp } from '/@vab/plugins/vab'
import { TenantEnum } from '/@/constance/tenant'
import { getConfig } from '/@/api/helper'

export const useUserStore = defineStore('user', {
  state: (): UserModuleType => ({
    token: getToken() as string,
    username: '',
    username_zh: '',
    account_type: 0,
    tenants: [],
    tenant: {},
    partner: {},
    partners: [],
    uid: 0,
    loading: true,
    config: {
      duty: {
        name: '',
      },
      notice_data: [
        {
          is_push: false,
          title: '新增出口IP公告',
          data: '我们新增了网络出口IP，辛苦友商在3月1前配置好白名单；IP名单如下：8.138.52.8、8.138.52.9、8.138.52.10、8.138.52.11',
          create_time: '2024-02-22 00:00:00',
        },
      ],
    },
  }),
  getters: {
    getToken: (state) => state.token,
    getUsername: (state) => state.username,
    getUsername_zh: (state) => state.username_zh,
    getAccount_type: (state) => state.account_type,
    getTenants: (state) => state.tenants,
    getTenant: (state) => state.tenant,
    getUid: (state) => state.uid,
  },
  actions: {
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken(token: string) {
      this.token = token
      setToken(token)
    },
    async setConfig() {
      const data = await getConfig()
      data && (this.config = data)
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername(username: string) {
      this.username = username
    },
    /**
     * @description 设置用户名
     * @param {*} username_zh
     */
    setUsername_Zh(username_zh: string) {
      this.username_zh = username_zh
    },
    /**
     * @description 设置账号类型
     * @param account_type
     */
    setAccountType(account_type: number) {
      this.account_type = account_type
    },
    /**
     * @description 设置租户列表
     * @param tenants
     */
    setTenants(tenants: TenantType[]) {
      this.tenants = tenants
    },
    /**
     * @description 设置租户
     * @param tenant
     */
    setTenant(tenant: TenantType = {}) {
      if (import.meta.env.MODE === 'osim') {
        cookie.set('tenant_key', tenant.tenant_key ? tenant.tenant_key : '', {
          domain: 'osim.intra.xiaojukeji.com',
        })
        cookie.set(
          'tenant_type',
          tenant.tenant_type || tenant.tenant_type === TenantEnum.PBD
            ? tenant.tenant_type
            : '',
          {
            domain: 'osim.intra.xiaojukeji.com',
          }
        )
      } else {
        cookie.set('tenant_key', tenant.tenant_key ? tenant.tenant_key : '')
        cookie.set(
          'tenant_type',
          tenant.tenant_type || tenant.tenant_type === TenantEnum.PBD
            ? tenant.tenant_type
            : ''
        )
      }
      localStorage.setItem('selectedTenant', JSON.stringify(tenant))
      this.tenant = tenant
      this.partners = tenant.partner ? tenant.partner : []
    },
    setPartners(partners: PartnerType[]) {
      this.partners = partners
    },
    setPartner(partner: PartnerType = {}) {
      // cookie.set('partner_id', partner.partner_id ? partner.partner_id : '')
      localStorage.setItem('selectedPartner', JSON.stringify(partner))
      if (import.meta.env.MODE === 'osim') {
        cookie.set(
          'platform_type',
          partner.platform_type ? partner.platform_type.toString() : '',
          { domain: 'osim.intra.xiaojukeji.com' }
        )
        cookie.set(
          'partner_id',
          partner.partner_id ? partner.partner_id.toString() : '',
          { domain: 'osim.intra.xiaojukeji.com' }
        )
      } else {
        cookie.set(
          'platform_type',
          partner.platform_type ? partner.platform_type.toString() : ''
        )
        cookie.set(
          'partner_id',
          partner.partner_id ? partner.partner_id.toString() : ''
        )
      }
      this.partner = partner
    },

    setLoading() {
      this.loading = false
    },
    /**
     * @description 设置租户
     * @param uid
     */
    setUid(uid: any) {
      this.uid = uid
    },
    /**
     * @description 登录拦截放行时，设置虚拟角色
     */
    setVirtualRoles() {
      const aclStore = useAclStore()
      aclStore.setFull(true)
      this.setUsername('admin')
      this.setUsername_Zh('管理员')
      cookie.set('account_type', '0')
      cookie.remove('tenant_key')
      cookie.remove('tenant_type')
      cookie.remove('partner_id')
      cookie.remove('project_id')
      cookie.remove('platform_type')
      setTimeout(() => {
        const tenants: TenantType[] = [
          {
            tenant_key: '阳光出行',
            tenant_type: TenantEnum.TRIPCLOUD,
            partner: [
              {
                partner_id: 10050,
                platform_type: 1,
                platform_type_name: '滴滴开放平台',
              },
              {
                partner_id: 10051,
                platform_type: 2,
                platform_type_name: '花小猪开放平台',
              },
            ],
          },
          {
            tenant_key: '百度地图',
            tenant_type: TenantEnum.PBD,
            partner: [
              {
                partner_id: 10007,
                platform_type: 3,
                platform_type_name: 'PBD开放平台',
              },
            ],
          },
        ]
        this.setTenants(tenants)
        this.setUid(123)
        // 恢复租户信息
        const selectedTenant = localStorage.getItem('selectedTenant')
        const selectedPartner = localStorage.getItem('selectedPartner')
        if (selectedTenant) {
          this.setTenant(JSON.parse(selectedTenant))

          if (selectedPartner) {
            this.setPartner(JSON.parse(selectedPartner))
          } else {
            this.setPartner(JSON.parse(selectedTenant).partner[0])
          }
        } else {
          this.setTenant(tenants[0])
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.setPartner(tenants[0].partner![0])
        }
        this.setLoading()
      }, 1000)
    },
    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    async getUserInfo() {
      cookie.remove('tenant_key')
      cookie.remove('tenant_type')
      cookie.remove('partner_id')
      cookie.remove('platform_type')
      const {
        data: { username, username_zh, account_type, tenants, uid },
      } = await getUserInfo()
      /**
       * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
       * username {String}
       * avatar {String}
       * roles {List}
       * ability {List}
       */
      if (
        (username && !isString(username)) ||
        (username_zh && !isString(username_zh)) ||
        (account_type && !isNum(account_type))
      ) {
        const err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确'
        gp.$baseMessage(err, 'error', 'hey')
        throw err
      } else {
        // 如不使用username用户名,可删除以下代码
        if (username) {
          this.setUsername(username)
          this.setToken(username)
        }
        if (username_zh) this.setUsername_Zh(username_zh)
        if (uid) this.setUid(uid)
        if (tenants) {
          this.setTenants(tenants)
          // 恢复租户信息
          const selectedTenant = localStorage.getItem('selectedTenant')
          const selectedPartner = localStorage.getItem('selectedPartner')
          if (selectedTenant) {
            this.setTenant(JSON.parse(selectedTenant))
            if (selectedPartner) {
              this.setPartner(JSON.parse(selectedPartner))
            } else {
              this.setPartner(JSON.parse(selectedTenant).partner[0])
            }
          } else {
            this.setTenant(tenants[0])
            this.setPartner(tenants[0].partner[0])
          }
        }
        this.setLoading()
        // 0:sso 1:passport
        if (account_type) this.setAccountType(account_type)
      }
    },
    /**
     * @description 退出登录
     */
    async logout() {
      await this.resetAll()
    },
    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    async resetAll() {
      // const tabsStore = useTabsStore()
      this.setUsername('')
      this.setUsername_Zh('')
      this.setTenants([])
      this.setTenant()
      this.setPartner()
      this.setUid(0)
      // tabsStore.delAllVisitedRoutes()
      // await resetRouter()
      cookie.remove('tenant_key')
      cookie.remove('tenant_type')
      cookie.remove('partner_id')
      cookie.remove('platform_type')
      localStorage.removeItem('selectedTenant')
      localStorage.removeItem('selectedPartner')
      localStorage.removeItem('open_username')
      removeToken()
    },
  },
})
