import Cookies from 'js-cookie'
import { useUserStore } from '/@/store/modules/user.ts'
import { loginInterception } from '/@/config'

export const getCookies = (): string | undefined => {
  return Cookies.get('open_username')
}

export const judgeIsLogin = () => {
  const cookie = getCookies()
  if (cookie !== undefined) return true
  else return false
}

export const getLoginInfo = async () => {
  const { getUserInfo, setVirtualRoles } = useUserStore()
  if (judgeIsLogin()) {
    await getUserInfo()
  } else if (!loginInterception) {
    setVirtualRoles()
  }
}
