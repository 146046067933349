<template>
  <div class="vab-header">
    <div class="header-content">
      <vab-logo class="left-logo" />
      <el-menu
        active-text-color="#7F00FF"
        background-color="#f5f4fb"
        :default-active="activeMenu"
        mode="horizontal"
      >
        <template v-for="(item, index) in menu" :key="index + item['title']">
          <vab-menu :item="item" />
        </template>
      </el-menu>
      <div class="right-panel">
        <div class="control" @click="toManage">控制台</div>
        <vab-tenant redirect-path="/index" />
        <vab-avatar :is-login="isLogin" :username="username" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { judgeIsLogin } from '~/src/utils/cookies'
  import { loginInterception, menu } from '/@/config/index'
  import { useUserStore } from '/@/store/modules/user'
  const { username } = storeToRefs(useUserStore())

  const isLogin = computed(
    () => (username.value !== '' && judgeIsLogin()) || !loginInterception
  )
  const route = useRoute()
  const router = useRouter()
  const activeMenu = ref('')
  watch(
    route,
    () => {
      activeMenu.value = route.matched[0].path
    },
    { immediate: true }
  )
  const toManage = () => {
    if (isLogin.value) router.push('/manage')
    else router.push('/login')
  }
</script>

<style lang="scss" scoped>
  .vab-header {
    height: 56px;
    width: 100%;
    background-color: rgba($color: #ffffff, $alpha: 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    .header-content {
      width: 100%;
      height: 100%;
      padding: 0 40px;
      display: flex;
      align-items: center;
      min-width: 1440px;
      box-sizing: border-box;
      justify-content: space-between;
      .left-logo {
        position: relative;
      }
      .right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 500px;
      }
    }
    .el-menu {
      flex-grow: 1;
      max-width: 965px;
      height: 56px;
      background: none;
      ::v-deep(.el-menu-item, .is-active) {
        &:hover {
          background-color: #f5f3f9;
        }
        &:focus {
          background-color: #f5f3f9;
        }
      }
    }
    .control {
      font-family: PingFangSC-Medium;
      font-size: 16px;
      color: #101626;
      font-weight: 500;
      margin-right: 40px;
      cursor: pointer;
      &:hover {
        color: #7f00ff;
      }
    }
  }
</style>
