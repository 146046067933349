<template>
  <el-container>
    <vab-header class="header" />
    <el-main><vab-router-view /></el-main>
    <el-footer><vab-footer /></el-footer>
    <vab-helper />
  </el-container>
</template>

<script lang="ts" setup>
  import { useUserStore } from '/@/store/modules/user'
  import { judgeIsLogin } from '~/src/utils/cookies'
  const userStore = useUserStore()
  const { setConfig } = userStore
  const { loading } = storeToRefs(userStore)

  if (!judgeIsLogin()) {
    setConfig()
  } else {
    if (!loading.value) {
      setConfig()
    } else watch(loading, setConfig)
  }
</script>

<style lang="scss" scoped>
  .el-container {
    // background-image: linear-gradient(#fff 0%, #faf9fe 50%, #fff);
    background-color: #f8f8fc;
    .header,
    .el-main,
    .el-footer {
      padding: 0;
    }
    .header {
      position: sticky;
      top: 0;
      height: 56px;
      z-index: 99;
    }
    .el-main {
      overflow: unset;
    }
    .el-footer {
      min-height: 400px;
      background-color: #f6f7f9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      color: #1a1a1a;
    }
  }
</style>
