import request from '/@/utils/request'

/**
 * 获取值班人名字
 */
export function getPersonOnDuty() {
  return request({
    url: '/duty/today',
    method: 'get',
  })
}

export async function getConfig(): Promise<configData> {
  const { data } = await request({
    url: '/config',
    method: 'post',
  })
  return data
}

export interface noticeData {
  create_time: string
  data: string
  is_push: boolean
  title: string
}

export interface configData {
  duty: {
    name: string
  }
  notice_data: noticeData[]
}
