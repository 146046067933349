<template>
  <div class="footer-content">
    <div>
      <img
        alt=""
        class="logo-pic"
        src="https://s3-gz01.didistatic.com/packages-mait/img/6TSff6kUyn1720151299052.png"
      />
    </div>
    <div class="footer-link">
      <div class="footer-link-title">友情链接</div>
      <div class="footer-link-item">
        <a href="https://www.didiglobal.com/" target="_blank">滴滴官网</a>
      </div>
      <div class="footer-link-item">
        <a
          href="https://www.didiglobal.com/about-didi/about-us"
          target="_blank"
        >
          关于滴滴
        </a>
      </div>
      <div class="footer-link-item">
        <a
          href="https://www.didiglobal.com/about-didi/cultural"
          target="_blank"
        >
          文化与价值观
        </a>
      </div>
      <div class="footer-link-item">
        <a
          href="https://www.didiglobal.com/about-special/milestone"
          target="_blank"
        >
          发展历程
        </a>
      </div>
      <div class="footer-link-item">
        <a
          href="https://www.didiglobal.com/about-didi/responsibility"
          target="_blank"
        >
          社会责任
        </a>
      </div>
    </div>
    <div class="footer-link">
      <div class="footer-link-title">热门推荐</div>
      <div class="footer-link-item">
        <a href="#/manage" target="_blank">在线联调工具</a>
      </div>
      <div class="footer-link-item">
        <a href="javascript:void(0);" onclick="alert('此功能正在开发中...');">
          商家管理平台
        </a>
      </div>
    </div>
    <div class="footer-link">
      <div class="footer-link-title">服务与支持</div>
      <div class="footer-link-item">
        <a href="#/document" target="_blank">文档中心</a>
      </div>
      <div class="footer-link-item">
        <a href="javascript:void(0);" onclick="alert('此功能正在开发中...');">
          工单客服
        </a>
      </div>
    </div>
    <!-- <div class="footer-link">
      <div class="footer-link-title">联系我们</div>
    </div> -->
  </div>
  <div class="footer-copyright">
    Copyright
    <vab-icon icon="copyright-line" />
    {{ fullYear }} 滴滴&花小猪开放平台
  </div>
</template>

<script lang="ts" setup>
  const fullYear = new Date().getFullYear()
</script>

<style lang="scss" scoped>
  .footer-content {
    width: 1280px;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    font-family: MiSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif !important;
    .logo-pic {
      width: 236px;
      height: 33px;
      position: relative;
      left: -100px;
    }
    .footer-link {
      width: 260px;
      box-sizing: border-box;
      padding-left: 40px;
      .footer-link-title {
        margin-top: 5px;
        font-weight: 500;
        font-size: 20px;
        line-height: 19px;
        color: #1a1a1a;
        margin-bottom: 24px;
      }
      .footer-link-item {
        a {
          line-height: 30px;
          font-weight: 400;
          font-size: 16px;
          color: #666;
          cursor: pointer;
        }
      }
    }
  }
  .footer-copyright {
    width: 1280px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: gray;
    border-top: 1px solid #e0e0e0;
  }
</style>
