import request from '/@/utils/request'
import { sso_logout_url, passport_logout_url } from '/@/config/index'

export function getUserInfo() {
  return request({
    url: '/auth/user',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'get',
  })
}
