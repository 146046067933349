import type { VabRouteRecordRaw } from '/@/router/types'
import { isExternal } from '/@/utils/validate'
/**
 * @description 根据roles数组拦截路由
 * @param routes 路由
 * @param rolesControl 是否进行权限控制
 * @param baseUrl 基础路由
 * @returns {[]}
 */
export function filterRoutes(
  routes: VabRouteRecordRaw[],
  rolesControl: boolean,
  baseUrl = '/'
): VabRouteRecordRaw[] {
  return routes.map((route: VabRouteRecordRaw) => {
    route = { ...route }
    if (route.path !== '*' && !isExternal(route.path)) {
      if (baseUrl.slice(-1) === '/')
        route.path =
          baseUrl + (route.path[0] === '/' ? route.path.slice(1) : route.path)
      else
        route.path =
          baseUrl + (route.path[0] === '/' ? route.path : `/${route.path}`)
    }
    if (route.children && route.children.length > 0) {
      route.children = filterRoutes(route.children, rolesControl, route.path)
      if (route.children.length > 0) {
        if (!route.redirect)
          route.redirect = route.children[0].redirect
            ? route.children[0].redirect
            : route.children[0].path
      }
    }
    return route
  })
}

export function filterManageRoutes(
  routes: VabRouteRecordRaw[],
  rolesControl: boolean,
  baseUrl = '/'
): VabRouteRecordRaw[] {
  return routes.map((route: VabRouteRecordRaw) => {
    route = { ...route }
    if (route.path !== '*' && !isExternal(route.path)) {
      if (baseUrl.slice(-1) === '/')
        route.path =
          baseUrl + (route.path[0] === '/' ? route.path.slice(1) : route.path)
      else
        route.path =
          baseUrl + (route.path[0] === '/' ? route.path : `/${route.path}`)
    }
    if (route.children && route.children.length > 0) {
      route.children = filterRoutes(route.children, rolesControl, route.path)
      if (route.children.length > 0) {
        if (!route.redirect)
          route.redirect = route.children[0].redirect
            ? route.children[0].redirect
            : route.children[0].path
      }
    }
    return route
  })
}
