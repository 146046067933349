<template>
  <div :class="['button', sizeClass, typeClass]">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
  interface propType {
    size: 'mini' | 'small' | 'medium' | 'large'
    type: 'primary' | 'default'
  }
  const props = defineProps<propType>()
  const sizeClass = computed(() => {
    return `size-${props.size}`
  })

  const typeClass = computed(() => {
    return `type-${props.type}`
  })
</script>

<style lang="scss" scoped>
  .button {
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
  }
  .size-small {
    width: 100px;
    height: 40px;
    line-height: 40px;
    border: 1px solid rgba(236, 237, 238, 1);
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: 600;
  }
  .size-medium {
    width: 146px;
    height: 52px;
    line-height: 52px;
    background: #ffffff;
    border: 1px solid rgba(236, 237, 238, 1);
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: rgba(16, 22, 38, 0.9);
    font-weight: 500;
    box-sizing: border-box;
  }
  .size-large {
    width: 340px;
    height: 60px;
    line-height: 60px;
    border-radius: 12px;
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #ffffff;
    font-weight: 500;
  }
  .size-mini {
    padding: 0 18px;
    border-radius: 4px;
    font-family: PingFangSC-Medium;
  }
  .type-default {
    color: #101626;
    background: #ffffff;
    border: 1px solid #ecedee;
    &:hover {
      transition: 0.3s;
      color: #000000;
    }
  }
  .type-primary {
    background: #7f00ff;
    color: #ffffff;
    border: none;
    &:hover {
      transition: 0.3s;
      background: #5400a8;
    }
  }
</style>
