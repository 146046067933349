<template>
  <div class="vab-logo">
    <router-link to="/">
      <div class="logo-container">
        <span class="logo">
          <img alt="Logo" src="/@/assets/flower_pig/花小猪打车logo.png" />
        </span>
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
  .vab-logo {
    height: 100%;
    display: inline-block;
    .logo-container {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      .logo {
        height: 30px;
        width: 212px;
        margin-right: 8px;
        img {
          height: 28px;
        }
      }
    }
  }
</style>
